import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getCleanedString } from '../../utils/utilities'

function initializeDestinationIndexHeaderUtagEvents(remove = false) {
  const header = document.querySelector('.destination-index-header')

  if (!header) return
  if (remove) {
    if (!header.classList.contains('utag-events-initialized')) return

    const utagViewTypeDestinationList = document.querySelectorAll('.utag-view-type-destination-list')
    utagViewTypeDestinationList.forEach(element => {
      element.removeEventListener('click', sendUtagEvent)
    })

    header.classList.remove('utag-events-initialized')
  } else {
    if (header.classList.contains('utag-events-initialized')) return

    const utagViewTypeDestinationList = document.querySelectorAll('.utag-view-type-destination-list')
    utagViewTypeDestinationList.forEach(element => {
      element.addEventListener('click', () => {
        sendUtagEvent({
          data: {
            event_name: 'view_by',
            event_cat: 'destinations',
            event_act: 'view by',
            event_lbl: getCleanedString(element.getAttribute('data-view-type')),
            event_purpose: 'inspiration',
            event_structure: 'body',
          },
        })
      })
    })

    header.classList.add('utag-events-initialized')
  }
}

export function initializeUtagEvents() {
  initializeDestinationIndexHeaderUtagEvents()
}

export function destroyUtagEvents() {
  initializeDestinationIndexHeaderUtagEvents(true)
}
